<template>
  <ion-list>
    <div v-for="abstract in abstracts" :key="abstract.id">
      <router-link :to="'/app/abstracts/' + abstract.id" v-if="abstract.code">
        <ion-item detail>
          <ion-label>
            <h2 ><strong><span >{{abstract.code}} - </span> <span v-html="abstract.title"></span></strong></h2>
            <small v-html="abstract.authors"></small>
          </ion-label>
        </ion-item>
      </router-link>
    </div>
  </ion-list>
</template>
<script>
import {IonItem, IonLabel} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";
export default defineComponent({
  name: "Abstracts",
  data() {
    return {}
  },
  methods: {
    ...mapActions('googleanalytics',['trackWithLabel']),

  },
  props: ['abstracts'],
  components: {
    IonItem,
    IonLabel
  },

    watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'Abstracts') {
           this.trackWithLabel("Abstract list")
        }
      }
    }
  }

});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}
</style>